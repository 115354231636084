<template>
  <Trap :disabled="!parentIsVisible">
    <div
      class="mobile-menu-language"
      :aria-label="$trans('/accessibility/languagenavigation')"
      @keyup.27="toggleVisible"
      data-cy="mobile-menu-language"
    >
      <button
        class="mobile-menu-language--button"
        @click="toggleVisible"
        aria-haspopup="true"
        id="mobile-menu-language-button"
        aria-controls="mobile-menu-language-overlay"
        :lang="currentLanguage"
        :aria-expanded="parentIsVisible ? 'true' : 'false'"
        data-cy="menu-language-toggle"
      >
        <icon glyph="#icon-earth"></icon>
        <span class="mobile-menu-language-button__text">
          {{ currentLangCodeCapital() }}
        </span>
      </button>
      <div
        class="mobile-menu-language--overlay"
        v-show="parentIsVisible"
        id="mobile-menu-language-overlay"
        @click.self="toggleVisible"
        @keyup.27="toggleVisible"
        aria-labelledby="mobile-menu-language-button"
        role="region"
        v-cloak
      >
        <div class="container">
          <div class="row">
            <div class="mobile-menu-language--overlay__wrapper">
              <ul class="mobile-menu-language__list" v-if="hasLanguages">
                <li
                  v-for="language of languages"
                  class="mobile-menu-language__list-item"
                  v-bind:key="language.DisplayName"
                >
                  <a
                    class="mobile-menu-language__link"
                    :class="{ selected: language.Selected }"
                    :href="language.Url"
                    :lang="language.Name"
                    data-cy="mobile-menu-language-link"
                    v-html="language.DisplayName"
                  >
                  </a>
                  <icon glyph="#icon-vector"></icon>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Trap>
</template>

<script>
import _isNil from 'lodash/isNil';
import Trap from 'vue-focus-lock';

export default {
  name: 'MobileMenuLanguage',
  props: {
    languages: { type: Array, default: () => [] },
    setVisibleMenu: { type: Function },
    parentIsVisible: { type: Boolean, default: false }
  },
  components: { Trap },
  data() {
    return {
      currentLanguage: window.site_locale
    };
  },
  computed: {
    hasLanguages() {
      return !_isNil(this.languages) && this.languages.length > 0;
    }
  },
  methods: {
    toggleVisible() {
      this.setVisibleMenu(2);
    },
    currentLangCodeCapital() {
      return this.currentLanguage.toUpperCase();
    }
  }
};
</script>
